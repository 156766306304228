import "../index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReviewProjectCard from "../../../ui/ReviewProjectCard";
import { withRouter } from "react-router-dom";
import { sortByArea } from "../../../actions";
import { sortProjects } from "../../../services/helpers";
import v1, { v2 } from "../../../services/axios";
import preloader from "../../../preloader.gif";
import moment from "moment";
import { ProjectListCard } from "./ProjectListCard";
import ConfirmModal from "../../../ui/ConfirmModal";
import InlineConfirmModal from "../../../ui/InlineConfirmModal";

function ProjectsList({
  match,
  bookmark,
  setQueuedProject,
  getOneReport,
  report,
  property,
  updateStep,
  setProjectId,
  queuedProjects,
  setQueuedProjects,
  updatePageNumber,
  updateReport,
  userCanEdit,
  showListView,
  setBookmark,
  setShowListView,
  setMessage,
  setProjectsFetched,
}) {
  const [showConfirmDeleteSelected, setShowConfirmDeleteSelected] =
    useState(false);
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [projects, setProjects] = useState([]);
  const [readyProjects, setReadyProjects] = useState([]);
  const [reviewedProjects, setReviewedProjects] = useState([]);
  const [majordomoNotes, setMajordomoNotes] = useState(null);
  const [showQueuedConfirm, setShowQueuedConfirm] = useState(false);
  const [showQueued, setShowQueued] = useState(true);
  const [sort, setSort] = useState(
    localStorage.getItem("project-list-sort") || "pageStart"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (bookmark && document.getElementById(bookmark)) {
      document.getElementById(bookmark).scrollIntoView();
      setBookmark(null);
    } else {
      document.getElementsByClassName("md-diy-sidebar")[0].scrollTo(0, 0);
    }

    hydrate();
  }, []);

  useEffect(() => {
    if (report && report.majordomo_notes) {
      setMajordomoNotes(report.majordomo_notes);
    }
  }, [report]);

  useEffect(() => {
    if (projects.length) {
      const allReviewed = [];
      const allReady = [];

      projects.map((project) => {
        if (project.status === "REVIEWED") {
          allReviewed.push(project);
        } else {
          allReady.push(project);
        }
      });

      setReadyProjects(allReady);
      setReviewedProjects(allReviewed);
    }
  }, [projects]);

  useEffect(() => {
    localStorage.setItem("project-list-sort", sort);
    const sortedProjects = sortProjects(sort, projects);
    setProjects(sortedProjects);
  }, [sort]);

  async function hydrate() {
    if (report && report.majordomo_notes) {
      setMajordomoNotes(report.majordomo_notes);
    }

    dispatch(sortByArea(property.property_log_id));
    await getProjects(sort);

    const checked = JSON.parse(localStorage.getItem("checkedProjects"));
    if (checked && checked.length) {
      setCheckedProjects([...checked]);
    }
  }

  async function getProjects(sort, fetched) {
    try {
      setProjectsFetched(false);
      setFetched(fetched);
      const projectsResponse = await v1.get(
        `/domoreport/uploader/allProjectsFor/${match.params.report_id}`
      );
      if (projectsResponse.data) {
        let sortedProjects = sortProjects(sort, projectsResponse.data.Projects);

        const { data, status } = await v2.get(
          `/domoreports/${report.unique_id}/automation/defects/`
        );

        if (status === 200) {
          sortedProjects.map((project) => {
            data.map((defect) => {
              if (project.domoreport_project_log_id === defect.projectId) {
                project.defect = defect;
              }
            });
          });
        }

        const queued = JSON.parse(localStorage.getItem("queued-projects")) || {
          [report.unique_id]: [],
        };
        setQueuedProjects(queued);
        setProjects(sortedProjects);
        setFetched(true);
        setProjectsFetched(true);
      }
    } catch (error) {
      setFetched(true);
      console.error(error);
    }
  }

  async function startClassification(e) {
    e.preventDefault();
    try {
      const queued = JSON.parse(localStorage.getItem("queued-projects"));
      if (
        queued &&
        queued[report.unique_id] &&
        queued[report.unique_id].length
      ) {
        const { status } = await v2.post(
          `/domoreports/${report.unique_id}/automation/defects/`,
          queued[report.unique_id]
        );
        if (status === 201) {
          setMessage("Projects submitted for classification");
          getOneReport(report.domoreport_log_id);
          getProjects(sort);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function toggleShowConfirmDeleteSelected(e) {
    if (e) {
      e.preventDefault();
    }
    setShowConfirmDeleteSelected(!showConfirmDeleteSelected);
  }

  async function deleteSelected() {
    checkedProjects.map(async (input, index) => {
      const { checked, id } = input;

      if (checked) {
        const inputProject = projects.find(
          (project) => project.domoreport_project_log_id === parseInt(id)
        );

        let url = `/domoreport/uploader/project/${id}`;
        if (inputProject && inputProject.defect_id) {
          url = `/domoreport/uploader/project/${id}?defect=${inputProject.defect_id}`;
        }

        try {
          await v1.delete(url);
          if (index === checkedProjects.length - 1) {
            toggleShowConfirmDeleteSelected();
            localStorage.removeItem("checkedProjects");
            setCheckedProjects([]);
            getProjects(sort);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  function handleCheck(e, status) {
    let selectedProjects = [];
    let currentProjects =
      status === "REVIEWED" ? reviewedProjects : readyProjects;
    if (e.nativeEvent.shiftKey) {
      const lastChecked = checkedProjects[checkedProjects.length - 1];
      const projectIds = currentProjects.map((p) =>
        p.domoreport_project_log_id.toString()
      );
      const startIndex = projectIds.indexOf(lastChecked.id) + 1;
      const endIndex = projectIds.indexOf(e.target.id) + 1;

      const selected =
        startIndex < endIndex
          ? currentProjects.slice(startIndex, endIndex)
          : currentProjects.slice(endIndex - 1, startIndex);
      selectedProjects = selected.map((p) => ({
        id: p.domoreport_project_log_id.toString(),
        checked: e.target.checked,
      }));
    } else {
      selectedProjects.push({
        id: e.target.id,
        checked: e.target.checked,
      });
    }

    if (e.target.checked) {
      localStorage.setItem(
        "checkedProjects",
        JSON.stringify([...checkedProjects, ...selectedProjects])
      );
      setCheckedProjects([...checkedProjects, ...selectedProjects]);
    } else {
      const updatedProjects = checkedProjects.filter(
        (project) => project.id !== e.target.id
      );
      setCheckedProjects(updatedProjects);
      localStorage.setItem("checkedProjects", JSON.stringify(updatedProjects));
    }
  }

  function getPriority(id) {
    let priority = "Urgent";
    switch (id) {
      case 1:
        priority = "Urgent";
        break;
      case 2:
        priority = "Pressing";
        break;
      case 3:
        priority = "Cosmetic";
        break;
      default:
        priority = "Urgent";
    }
    return priority;
  }

  async function deleteQueued() {
    const queued = JSON.parse(localStorage.getItem("queued-projects")) || {};
    delete queued[report.unique_id];

    localStorage.setItem("queued-projects", JSON.stringify(queued));
    setQueuedProjects(queued);
    setShowQueuedConfirm(false);
  }

  let readyProjectsPages = [];
  let reviewedProjectsPages = [];

  return (
    <div className="md-diy-sidebar-content">
      <InlineConfirmModal
        title="Delete Selected Projects"
        subtext={`Are you sure you want to delete ${checkedProjects.length} projects?`}
        action="Delete"
        variant="danger"
        show={showConfirmDeleteSelected}
        close={toggleShowConfirmDeleteSelected}
        handleSubmit={deleteSelected}
      />

      <InlineConfirmModal
        title="Delete Queued Projects"
        subtext={`Are you sure you want to delete all ${
          queuedProjects[report.unique_id] &&
          queuedProjects[report.unique_id].length
        } queued projects for this report? This cannot be undone and all work will be lost.`}
        action="Delete"
        variant="danger"
        show={showQueuedConfirm}
        close={() => setShowQueuedConfirm(false)}
        handleSubmit={deleteQueued}
      />

      <div className="md-diy-sidebar-info">
        <h3 className="md-diy-sidebar-header m-0">Projects List</h3>
        <div className="view-options">
          <div className="view-sort">
            <p>Sort by: </p>
            <select
              onChange={(e) => setSort(e.target.value)}
              defaultValue={sort}
            >
              <option value="priceHigh">Cost (High to Low)</option>
              <option value="priceLow">Cost (Low to High)</option>
              <option value="priority">Priority</option>
              <option value="createdAt">Recently Updated</option>
              <option value="alphabetical">Alphabetical</option>
              <option value="pageStart">Page Start</option>
            </select>
          </div>

          {userCanEdit && (
            <div className="view-icons">
              <p>List View:</p>
              <span onClick={() => setShowListView(true)}>
                <i className="fa fa-list" />
              </span>
              <span onClick={() => setShowListView(false)}>
                <i className="fa fa-th-large" />
              </span>
            </div>
          )}
        </div>

        <div className="md-diy-projects-wrapper">
          <div className="actions-wrapper">
            <div
              className={`md-diy-sidebar-projects mb-3 ${
                !userCanEdit ? "d-none" : ""
              }`}
            >
              <a
                href="#"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  updateStep(3);
                }}
              >
                + Add Project
              </a>
            </div>

            <div
              className={`md-diy-sidebar-projects mb-3 ${
                !userCanEdit ? "d-none" : ""
              }`}
            >
              <a
                href="#"
                className={`btn btn-danger ${
                  !!checkedProjects.length ? "" : "disabled"
                }`}
                onClick={toggleShowConfirmDeleteSelected}
              >
                Delete Selected
              </a>
            </div>
          </div>

          {!!report && report.status === "IDENTIFYING" && (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 font-weight-bold">
                Identification Started:{" "}
                {moment(report.submitted_for_identification_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}{" "}
                (
                {moment(report.submitted_for_identification_at)
                  .startOf("minute")
                  .fromNow()}
                )
              </p>
            </div>
          )}

          {!!report && report.status === "CLASSIFYING" && (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 font-weight-bold">
                Classification Started:{" "}
                {moment(report.submitted_for_classification_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}{" "}
                (
                {moment(report.submitted_for_classification_at)
                  .startOf("minute")
                  .fromNow()}
                )
              </p>
            </div>
          )}

          {fetched &&
            (!!projects.length ||
              (!!Object.keys(queuedProjects).length &&
                queuedProjects[report.unique_id] &&
                !!queuedProjects[report.unique_id].length)) && (
              <div className={!userCanEdit ? "no-edit" : ""}>
                {queuedProjects[report.unique_id] &&
                  !!queuedProjects[report.unique_id].length && (
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="my-3 py-2 border-bottom font-weight-bold">
                          Queued Projects -{" "}
                          {queuedProjects[report.unique_id].length} project(s)
                        </p>

                        <div>
                          {showQueued ? (
                            <i
                              className="fa fa-minus fa-lg mr-2 cursor-pointer"
                              onClick={() => setShowQueued(false)}
                            />
                          ) : (
                            <i
                              className="fa fa-plus fa-lg mr-2 cursor-pointer"
                              onClick={() => setShowQueued(true)}
                            />
                          )}
                          <i
                            className="fa fa-trash fa-lg cursor-pointer text-danger"
                            onClick={() => setShowQueuedConfirm(true)}
                          />
                        </div>
                      </div>

                      {showQueued &&
                        queuedProjects[report.unique_id].map(
                          (project, index) => {
                            if (
                              !project.results ||
                              (project.results &&
                                project.results.status === "CLASSIFYING")
                            ) {
                              return (
                                <div
                                  key={project.text}
                                  className={`md-diy-project needs-review`}
                                  onClick={() => {
                                    setQueuedProject(project);
                                    updateStep(9);
                                  }}
                                >
                                  <p style={{ width: "100%" }}>
                                    Page {project.pageStart}: {project.text}
                                  </p>
                                </div>
                              );
                            }
                          }
                        )}
                    </div>
                  )}

                <p
                  className={`my-3 py-2 border-bottom font-weight-bold ${
                    !readyProjects.length ? "d-none" : ""
                  }`}
                >
                  Ready For Review - {readyProjects.length} project(s)
                </p>
                {!!readyProjects.length &&
                  readyProjects.map((project) => {
                    const defaultChecked = checkedProjects.find(
                      (checkedProject) =>
                        parseInt(checkedProject.id) ===
                        project.domoreport_project_log_id
                    );
                    if (project.cache_project_name && showListView) {
                      if (
                        sort === "pageStart" &&
                        !readyProjectsPages.includes(
                          project.inspection_page_num_start
                        )
                      ) {
                        readyProjectsPages.push(
                          project.inspection_page_num_start
                        );
                        return (
                          <div key={project.domoreport_project_log_id}>
                            <p className="page-header">
                              Page {project.inspection_page_num_start}
                            </p>
                            <ProjectListCard
                              project={project}
                              updateStep={updateStep}
                              updatePageNumber={updatePageNumber}
                              getPriority={getPriority}
                              setProjectId={setProjectId}
                              // editView={editView}
                              handleCheck={handleCheck}
                              defaultChecked={
                                defaultChecked ? defaultChecked.checked : false
                              }
                            />
                          </div>
                        );
                      } else {
                        return (
                          <ProjectListCard
                            key={project.domoreport_project_log_id}
                            project={project}
                            updateStep={updateStep}
                            updatePageNumber={updatePageNumber}
                            getPriority={getPriority}
                            setProjectId={setProjectId}
                            // editView={editView}
                            handleCheck={handleCheck}
                            defaultChecked={
                              defaultChecked ? defaultChecked.checked : false
                            }
                          />
                        );
                      }
                    } else if (project.cache_project_name && !showListView) {
                      return (
                        <ReviewProjectCard
                          getProjects={getProjects}
                          key={project.domoreport_project_log_id}
                          project={project}
                          setBookmark={setBookmark}
                          setProjectId={setProjectId}
                          sort={sort}
                          updatePageNumber={updatePageNumber}
                          updateStep={updateStep}
                          userCanEdit={userCanEdit}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}

                <p
                  className={`my-3 py-2 border-bottom font-weight-bold ${
                    !reviewedProjects.length ? "d-none" : ""
                  }`}
                >
                  Reviewed - {reviewedProjects.length} project(s){" "}
                  {/* <span onClick={() => setEditView(!editView)}>
                    <i className="fa fa-pencil" />
                  </span> */}
                </p>
                {!!reviewedProjects.length &&
                  reviewedProjects.map((project) => {
                    const defaultChecked = checkedProjects.find(
                      (checkedProject) =>
                        parseInt(checkedProject.id) ===
                        project.domoreport_project_log_id
                    );
                    if (project.cache_project_name && showListView) {
                      if (
                        sort === "pageStart" &&
                        !reviewedProjectsPages.includes(
                          project.inspection_page_num_start
                        )
                      ) {
                        reviewedProjectsPages.push(
                          project.inspection_page_num_start
                        );
                        return (
                          <div key={project.domoreport_project_log_id}>
                            <p className="page-header">
                              Page {project.inspection_page_num_start}
                            </p>
                            <ProjectListCard
                              project={project}
                              updateStep={updateStep}
                              updatePageNumber={updatePageNumber}
                              getPriority={getPriority}
                              setProjectId={setProjectId}
                              // editView={editView}
                              handleCheck={handleCheck}
                              defaultChecked={
                                defaultChecked ? defaultChecked.checked : false
                              }
                            />
                          </div>
                        );
                      } else {
                        return (
                          <ProjectListCard
                            key={project.domoreport_project_log_id}
                            project={project}
                            updateStep={updateStep}
                            updatePageNumber={updatePageNumber}
                            getPriority={getPriority}
                            setProjectId={setProjectId}
                            // editView={editView}
                            handleCheck={handleCheck}
                            defaultChecked={
                              defaultChecked ? defaultChecked.checked : false
                            }
                          />
                        );
                      }
                    } else if (project.cache_project_name && !showListView) {
                      return (
                        <ReviewProjectCard
                          getProjects={getProjects}
                          key={project.domoreport_project_log_id}
                          project={project}
                          setBookmark={setBookmark}
                          setProjectId={setProjectId}
                          sort={sort}
                          updatePageNumber={updatePageNumber}
                          updateStep={updateStep}
                          userCanEdit={userCanEdit}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            )}
        </div>

        {!fetched && (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <img src={preloader} alt="" />
          </div>
        )}
      </div>

      <hr />

      <div className="md-diy-sidebar-form" style={{ paddingBottom: 10 }}>
        <label>Notes</label>
        <div className="md-diy-input-wrapper">
          <img
            src="/assets/icons/Description.svg"
            className="md-diy-input-icon"
            alt=""
          />
          <textarea
            rows="7"
            placeholder="Notes"
            value={majordomoNotes || ""}
            onChange={(e) => setMajordomoNotes(e.target.value)}
            style={{ marginBottom: 0 }}
          />
        </div>

        <a
          href="/"
          className={`btn btn-primary ${!userCanEdit ? "d-none" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            updateReport({ majordomo_notes: majordomoNotes });
          }}
        >
          Save Notes
        </a>

        {queuedProjects &&
          queuedProjects[report.unique_id] &&
          !!queuedProjects[report.unique_id].length &&
          report.status !== "CLASSIFYING" && (
            <a
              href="/"
              className={`btn btn-primary`}
              onClick={startClassification}
            >
              Start Classification
            </a>
          )}
      </div>
    </div>
  );
}
export default withRouter(ProjectsList);
