import "./edit.scss";
import React, { useEffect, useState } from "react";
import v1, { v2 } from "../../../services/axios";
import Typeahead from "../../DiyRepairTypeahead/typeahead";
import ProjectQualityCard from "./projectQualityCard";
import TradesTypeahead from "../../TradesTypeahead/TradesTypeahead";
import CreateFeatureModal from "../../../ui/CreateFeatureModal";
import ConfirmModal from "../../../ui/ConfirmModal";
import { sortProjects } from "../../../services/helpers";
import { withRouter } from "react-router-dom";
import InlineConfirmModal from "../../../ui/InlineConfirmModal";

const repairwiseDBKeyMap = {
  description: "cache_job_details",
  name: "xactware_quality",
  lowTotalPrice: "cache_project_cost_low",
  highTotalPrice: "cache_project_cost_high",
  lowMaterialPrice: "cache_material_cost_low",
  highMaterialPrice: "cache_material_cost_high",
  lowLaborPrice: "cache_labor_cost_low",
  highLaborPrice: "cache_labor_cost_high",
  lowEquipmentPrice: "cache_equipment_cost_low",
  highEquipmentPrice: "cache_equipment_cost_high",
  lowLaborMinimumPrice: "cache_licensed_contractor_cost_low",
  highLaborMinimumPrice: "cache_licensed_contractor_cost_high",
  highMarketPrice: "high_market_price",
  lowMarketPrice: "low_market_price",
};

const unitOfMeasureMap = require("../../../ui/FormFields/unit-of-measure-mappings.json");

function EditProject({
  match,
  report,
  property,
  projectId,
  uniqueId,
  userCanEdit,
  getProperty,
  updatePageNumber,
  updateReport,
  updateStep,
}) {
  const [defaultQuantity, setDefaultQuantity] = useState();
  const [allProjects, setAllProjects] = useState();
  const [project, setProject] = useState({});
  const [projectFeatureMap, setProjectFeatureMap] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPredictions, setShowPredictions] = useState(true);
  const [showCreateFeatureModal, setShowCreateFeatureModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [confirmModalOptions, setConfirmModalOptions] = useState({
    title: "Delete Project",
  });
  const planningText = "This estimate is for planning purposes. ";

  useEffect(() => {
    getProject();
    getAllProjects();
  }, []);

  useEffect(() => {
    if (
      Object.keys(project).length &&
      !project.jobDetails &&
      project.repair_wise_estimate_id &&
      project.repair_wise_job_id
    ) {
      getJobEstimate(null, project.repair_wise_estimate_id, false);
    }

    if (Object.keys(project) && project.defect && project.jobDetails) {
      validateProject();
    }
  }, [project]);

  useEffect(() => {
    if (project.xactware_quantity && !defaultQuantity) {
      setDefaultQuantity(project.xactware_quantity);
      updateQuantity(project.xactware_quantity, false);
    }
  }, [project.xactware_quantity]);

  useEffect(() => {
    if (projectId) {
      getProject(projectId);
    }
  }, [projectId]);

  async function getProject(id) {
    setLoading(true);
    try {
      const { data, status } = await v1.get(
        `/domoreport/uploader/project/${id || projectId}`
      );
      if (status === 200) {
        const defectData = await v2.get(
          `/domoreports/${uniqueId}/automation/defects/`
        );

        document.getElementsByClassName("md-diy-sidebar")[0].scrollTo(0, 0);
        scrollToPage(data.inspection_page_num_start, data);

        if (defectData.data.length) {
          const projectDefect = defectData.data.find(
            (defect) => defect.id === data.defect_id
          );

          if (projectDefect) {
            setProject({ ...data, defect: projectDefect });
          } else {
            setProject({ ...data });
          }
        } else {
          setProject({ ...data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  //Use repair_wise_job_id
  async function getJobEstimate(jobId, estimateId, useDefault) {
    setLoading(true);
    try {
      const { data, status } = await v2.get(
        `/domoreports/${uniqueId}/projects/${
          project.domoreport_project_log_id
        }/estimate${jobId ? `?jobId=${jobId}` : ""}${
          estimateId ? `?estimateId=${estimateId}` : ""
        }`
      );

      const jobDetailsOverride = getJobDetailsOverride(data, useDefault);

      let costDetails = {};
      if (project.xactware_quality && useDefault) {
        const qualityLevel = data.qualityLevels.find(
          (quality) => quality.name === project.xactware_quality
        );
        for (let key in qualityLevel) {
          if (repairwiseDBKeyMap[key]) {
            if (
              repairwiseDBKeyMap[key] === "cache_job_details" &&
              !project.job_details_override
            ) {
              costDetails = {
                ...costDetails,
                job_details_override: `${project.cache_job_details} ${qualityLevel[key]}`,
              };
            } else {
              costDetails = {
                ...costDetails,
                [repairwiseDBKeyMap[key]]: qualityLevel[key],
              };
            }
          }
        }
      }

      if (status === 200) {
        const newProject = {
          ...project,
          ...costDetails,
          jobDetails: { ...data },
          cache_project_name: data.name,
          cache_job_details: data.description,
          job_details_override: jobDetailsOverride,
          xactware_quality: jobId ? null : project.xactware_quality,
          xactware_quantity: data.quantity,
          repair_wise_estimate_id: data.id,
          repair_wise_job_id: data.jobId,
        };

        setProject({
          ...newProject,
        });
        if (jobId) {
          getProjectFeatureMap(jobId || project.repair_wise_job_id, newProject);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllProjects() {
    try {
      const sort = localStorage.getItem("project-list-sort");
      const projectData = await v1.get(
        `/domoreport/uploader/allProjectsFor/${match.params.report_id}`
      );

      if (projectData.status === 200) {
        let sortedProjects = sortProjects(sort, projectData.data.Projects);

        const { data, status } = await v2.get(
          `/domoreports/${uniqueId}/automation/defects/`
        );

        if (status === 200) {
          sortedProjects.map((project) => {
            data.map((defect) => {
              if (project.domoreport_project_log_id === defect.projectId) {
                project.defect = defect;
              }
            });
          });
        }

        setAllProjects(sortedProjects);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getProjectFeatureMap(jobId, newProject) {
    try {
      const { data, status } = await v1.get(
        `/domoreport/uploader/projectFeatureMap/${jobId}`
      );

      if (status === 200) {
        setProjectFeatureMap(data);

        let feature = {};
        property.rooms.map((room) => {
          room.features.map((feat) => {
            if (feat.feature_ref_id === data.feature_ref_id) {
              feature = feat;
            }
          });
        });

        let priority = project.project_priority_ref_id;
        if (project.repair_wise_job_id !== newProject.repair_wise_job_id) {
          priority = data.project_priority_ref_id;
        }

        setProject({
          ...newProject,
          project_priority_ref_id: priority,
          trade_id: data.trade_id,
          property_fact_id: feature.property_fact_id || 0,
          repair_wise_job_id: data.uuid,
        });
        setErrors({
          trade_id: !data.trade_id,
          property_fact_id: !feature.property_fact_id,
        });
      }
    } catch (error) {
      setProjectFeatureMap({
        feature_ref_id: null,
        area_ref_id: null,
      });
      setProject({
        ...newProject,
        project_priority_ref_id:
          newProject.repair_wise_job_id === project.repair_wise_job_id
            ? newProject.project_priority_ref_id
            : 1,
        trade_id:
          newProject.repair_wise_job_id === project.repair_wise_job_id
            ? newProject.trade_id
            : null,
        property_fact_id:
          newProject.repair_wise_job_id === project.repair_wise_job_id
            ? newProject.property_fact_id
            : null,
      });
      if (newProject.repair_wise_job_id !== project.repair_wise_job_id) {
        setErrors({
          trade_id: true,
          property_fact_id: true,
        });
      } else {
        setErrors({
          trade_id: !newProject.trade_id,
          property_fact_id: !newProject.property_fact_id,
        });
      }

      console.error(error);
    }
    setLoading(false);
  }

  function validateProject() {
    const allErrors = {
      feature_problem: !project.feature_problem,
      inspection_page_num_start: !project.inspection_page_num_start,
      inspection_page_num_end: !project.inspection_page_num_end,
      inspection_problem_comment: !project.inspection_problem_comment,
      xactware_project_id: !project.xactware_project_id,
      xactware_quantity: !project.xactware_quantity,
      xactware_quality: !project.xactware_quality,
      project_name_override:
        !project.cache_project_name || project.project_name_override === "",
      job_details_override:
        !project.cache_job_details || project.job_details_override === "",
      project_priority_ref_id: !project.project_priority_ref_id,
      trade_id: !project.trade_id,
    };

    let isValid = true;
    for (const error in allErrors) {
      if (allErrors[error]) {
        isValid = false;
      }
    }

    setErrors(allErrors);
    setLoading(false);

    return isValid;
  }

  async function updateProject() {
    setLoading(true);

    const isValid = validateProject();
    if (isValid) {
      try {
        const { status } = await v1.put(
          `/domoreport/uploader/project/${project.domoreport_project_log_id}`,
          {
            ...project,
            defect: {},
            jobDetails: {},
            status: "REVIEWED",
            project_added: undefined,
            project_last_modified: undefined,
          }
        );
        if (status === 200) {
          setShowImage(false);
          getNextProject();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const wrapper = document.getElementsByClassName("md-diy-sidebar");
      const invalid = document.getElementsByClassName("invalid");
      invalid[0].scrollIntoView();
      wrapper[0].scrollTop -= 20;
    }
  }

  function getNextProject() {
    const sort = localStorage.getItem("project-list-sort");
    const filteredProjects = allProjects.filter(
      (allProject) =>
        !!(allProject.status && allProject.status === "READY_FOR_REVIEW")
    );
    const sortedProjects = sortProjects(sort, filteredProjects);

    let nextIndex = null;
    sortedProjects.find((sortedProject, index) => {
      if (
        sortedProject.status &&
        sortedProject.status === "READY_FOR_REVIEW" &&
        sortedProject.domoreport_project_log_id ===
          project.domoreport_project_log_id &&
        index + 1 < sortedProjects.length &&
        sortedProjects[index + 1].status &&
        sortedProjects[index + 1].status === "READY_FOR_REVIEW"
      ) {
        nextIndex = index + 1;
      }
    });

    if (nextIndex && project.status === "READY_FOR_REVIEW") {
      getProject(sortedProjects[nextIndex].domoreport_project_log_id);
    } else {
      updateStep(1);
    }
  }

  async function deleteProject(id) {
    let url = `/domoreport/uploader/project/${id}`;
    if (project.defect && project.defect.id) {
      url = `/domoreport/uploader/project/${id}?defect=${project.defect.id}`;
    }

    try {
      const { status } = await v1.delete(url);
      if (status === 200) {
        setShowDeleteModal(false);
        setConfirmModalOptions({ title: "Delete Project" });
        getNextProject();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleIncompleteProject() {
    let majordomoNotes = `${report.majordomo_notes}\nPage ${project.inspection_page_num_start}: ${project.inspection_problem_comment}`;
    if (!report.majordomo_notes) {
      // Thanks for having Majordomo on your team for the second half of your home inspection. We appreciate the opportunity. If you have any questions or need any updates, simply email us or use the instant chat button on this page. We are here for you 24/7/365. \nNotes: \n\n
      majordomoNotes = `-Any reference to mold and/or bacterial growths should be reviewed by a specialist.\n\n-All structural and foundation issues should be reviewed by a structural specialist. \n\n-When needed, anti-tip devices for kitchen stoves and anti-siphon devices for spigots can be purchased at your local home store for $5-$10 and are easily installed.;\n\nNot enough information to estimate or project not in database:\n\nPage ${project.inspection_page_num_start}: ${project.inspection_problem_comment}`;
    } else if (
      report.majordomo_notes &&
      !report.majordomo_notes.includes(
        "Not enough information to estimate or project not in database:"
      )
    ) {
      majordomoNotes = `${report.majordomo_notes}\n\nNot enough information to estimate or project not in database:\n\nPage ${project.inspection_page_num_start}: ${project.inspection_problem_comment}`;
    }

    setShowDeleteModal(true);
    updateReport({ majordomo_notes: majordomoNotes });
    deleteProject(project.domoreport_project_log_id);
  }

  function handleProject(key, value) {
    if (key === "inspection_problem_comment") {
      setProject({
        ...project,
        [key]: value,
        feature_problem: value.split(".")[0],
      });
    } else {
      setProject({ ...project, [key]: value });
    }
  }

  async function updateQuantity(quantity, useDefault) {
    setLoading(true);
    setProject({ ...project, xactware_quantity: quantity });
    try {
      const { status } = await v2.patch(
        `/domoreports/${project.domoreport_log_id}/projects/${project.domoreport_project_log_id}/estimate`,
        { quantity, id: project.repair_wise_estimate_id }
      );
      if (status === 204) {
        getJobEstimate(null, project.repair_wise_estimate_id, useDefault);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleDefault(key) {
    switch (key) {
      case "feature_problem":
        const featureProblem = project.inspection_problem_comment.split(".");
        setProject({ ...project, feature_problem: featureProblem[0] });
        break;
      case "job_details_override":
        const jobDetailsOverride = getJobDetailsOverride(
          project.jobDetails,
          true
        );
        setProject({ ...project, job_details_override: jobDetailsOverride });
        break;
      case "project_name_override":
        setProject({ ...project, project_name_override: null });
        break;
      case "xactware_quantity":
        updateQuantity(project.jobDetails.defaultValue, true);
        break;
    }
  }

  function getJobDetailsOverride(jobDetails, useDefault) {
    let jobDetailsOverride = project.job_details_override;
    if (project.xactware_quality) {
      const qualityLevel = jobDetails.qualityLevels.find((level) => {
        return level.name === project.xactware_quality;
      });

      if (project.job_details_override && !useDefault) {
        jobDetailsOverride = project.job_details_override;
      } else if (qualityLevel) {
        jobDetailsOverride = `${jobDetails.description} ${qualityLevel.description}`;
      } else if (
        project.job_details_override &&
        !project.job_details_override.includes(jobDetails.description)
      ) {
        jobDetailsOverride = null;
      }
    }

    return jobDetailsOverride;
  }

  function handleForPlanning(e) {
    let currentText = project.job_details_override || project.cache_job_details;

    if (e.target.checked && !currentText.includes(planningText)) {
      setProject({
        ...project,
        job_details_override: `${planningText} ${currentText}`,
      });
    } else if (!e.target.checked && currentText.includes(planningText)) {
      setProject({
        ...project,
        job_details_override: currentText.replace(planningText, ""),
      });
    }
  }

  function scrollToPage(pageNum, project) {
    const { pdfViewer, initialized, downloadComplete } =
      window.PDFViewerApplication;
    let textMatch = undefined;
    if (initialized && downloadComplete) {
      const { textLayer } = pdfViewer._pages[pageNum - 1];

      if (textLayer) {
        textLayer.textDivs.find((span) => {
          const spanText = span.innerText
            .replaceAll(/[^a-zA-Z0-9]+/g, "")
            .replace(/\0/g, "")
            .replace(/  +/g, " ")
            .replaceAll(/[^a-zA-Z0-9]+/g, "")
            .trim();
          const projectText = project.inspection_problem_comment
            .replaceAll(/[^a-zA-Z0-9]+/g, "")
            .replace(/\0/g, "")
            .replace(/  +/g, " ")
            .replaceAll(/[^a-zA-Z0-9]+/g, "")
            .trim();
          if (spanText.search(projectText) !== -1) {
            textMatch = span;
          }
        });
      }
    }

    if (textMatch) {
      const container = document.getElementById("viewerContainer");
      textMatch.scrollIntoView();
      container.scrollTop -= 100;
    } else {
      if (pageNum < pdfViewer._pages.length) {
        updatePageNumber(pageNum);
      } else {
        updatePageNumber(pdfViewer._pages.length - 1);
      }
    }
  }

  if (!Object.keys(project).length) {
    return <div></div>;
  }

  return (
    <div className="edit-project">
      {loading && <div className="loading-overlay" />}

      <CreateFeatureModal
        project={project}
        projectFeatureMap={projectFeatureMap}
        property={property}
        show={showCreateFeatureModal}
        setProject={setProject}
        getProperty={getProperty}
        hideModal={() => setShowCreateFeatureModal(false)}
      />

      <InlineConfirmModal
        title={confirmModalOptions.title}
        subtext="Are you sure you want to delete this project?"
        action="Delete"
        variant="danger"
        show={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        handleSubmit={() => {
          if (
            confirmModalOptions.title === "Move & Delete Incomplete Project"
          ) {
            handleIncompleteProject();
          } else {
            deleteProject(project.domoreport_project_log_id);
          }
        }}
      />

      <h4 className="mb-3">{project.cache_project_name}</h4>

      <div className="project-input-wrapper">
        <label htmlFor="defectDescription">Defect Description</label>
        <div className="project-input">
          <textarea
            id="defectDescription"
            className={`${errors.inspection_problem_comment ? "invalid" : ""}`}
            placeholder="Defect description"
            value={project.inspection_problem_comment}
            onChange={(e) =>
              handleProject("inspection_problem_comment", e.target.value)
            }
            rows="3"
          />
        </div>
      </div>

      <div className="project-input-wrapper">
        <label htmlFor="defectTitle">
          Defect Title{" "}
          <span onClick={() => handleDefault("feature_problem")}>
            Use Default
          </span>
        </label>
        <div className="project-input">
          <input
            type="text"
            id="defectTitle"
            className={`${errors.feature_problem ? "invalid" : ""}`}
            placeholder="Defect title"
            value={project.feature_problem}
            onChange={(e) => handleProject("feature_problem", e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="project-input-wrapper">
            <label htmlFor="pageStart">
              Page Start{" "}
              <span
                onClick={() =>
                  scrollToPage(project.inspection_page_num_start, project)
                }
              >
                Scroll to Page
              </span>
            </label>
            <div className="project-input">
              <input
                type="number"
                id="pageStart"
                className={`${
                  errors.inspection_page_num_start ? "invalid" : ""
                }`}
                placeholder="Page Start #"
                value={project.inspection_page_num_start}
                onChange={(e) =>
                  handleProject("inspection_page_num_start", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="project-input-wrapper">
            <label htmlFor="pageEnd">
              Page End{" "}
              <span
                onClick={() =>
                  scrollToPage(project.inspection_page_num_end, project)
                }
              >
                Scroll to Page
              </span>
            </label>
            <div className="project-input">
              <input
                type="number"
                id="pageEnd"
                className={`${errors.inspection_page_num_end ? "invalid" : ""}`}
                placeholder="Page End #"
                value={project.inspection_page_num_end}
                onChange={(e) =>
                  handleProject("inspection_page_num_end", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>

      {project.defect && project.defect.predictions && (
        <div className="project-predictions-wrapper">
          <h4 onClick={() => setShowPredictions(!showPredictions)}>
            Project Predictions{" "}
            {showPredictions ? (
              <i className="fa fa-caret-up" />
            ) : (
              <i className="fa fa-caret-down" />
            )}
          </h4>
          <hr />
          {showPredictions &&
            project.defect.predictions.repair.map((prediction) => {
              if (prediction.id) {
                return (
                  <div
                    key={prediction.id}
                    onClick={() => {
                      getJobEstimate(prediction.id, null, true);
                    }}
                    className={`project-prediction col-12 ${
                      project.cache_project_name === prediction.name
                        ? "active"
                        : ""
                    }`}
                  >
                    <span>
                      {Math.round(prediction.score.toFixed(2) * 100)}% -{" "}
                      {prediction.name}
                    </span>
                    <p>{prediction.description}</p>
                  </div>
                );
              }
            })}
        </div>
      )}

      <div className="project-input-wrapper">
        <label htmlFor="projectSearch" id="projectSearchLabel">
          Project Search
        </label>
        <div className="project-input">
          <Typeahead
            getJobEstimate={getJobEstimate}
            project={project}
            setProject={setProject}
          />
        </div>
      </div>

      <div className="project-details-wrapper">
        <div className="project-details-text">
          <p>
            <b>Project Description</b>
          </p>
          <p>{project.cache_job_details}</p>
          {showImage ? (
            <div className="image-toggle">
              <p
                className="text-center mt-2 cursor-pointer"
                onClick={() => setShowImage(!showImage)}
              >
                Hide Image <i className="fa fa-caret-up" />
              </p>
            </div>
          ) : (
            <div className="image-toggle">
              <p
                className="text-center mt-2 cursor-pointer"
                onClick={() => setShowImage(!showImage)}
              >
                Show Image <i className="fa fa-caret-down" />
              </p>
            </div>
          )}
        </div>

        <div className={`project-details-image ${showImage ? "show" : ""}`}>
          {project.jobDetails && <img src={project.jobDetails.imageUrl} />}
        </div>

        <div className="project-input-wrapper">
          <label htmlFor="projectQuantity">
            Quantity{" "}
            <span onClick={() => handleDefault("xactware_quantity")}>
              Use Default
            </span>
          </label>
          <div className="project-input d-flex justify-content-between">
            <input
              type="number"
              id="projectQuantity"
              className={`mb-0 mr-2 ${
                errors.xactware_quantity ? "invalid" : ""
              }`}
              placeholder="Material quantity"
              value={project.xactware_quantity}
              disabled={!userCanEdit}
              onChange={(e) =>
                handleProject("xactware_quantity", parseInt(e.target.value))
              }
            />
            <button
              onClick={() => updateQuantity(project.xactware_quantity, true)}
            >
              Update Quantity
            </button>
          </div>
          {project.jobDetails && (
            <p>
              in units of{" "}
              {
                unitOfMeasureMap.unitsOfMeasure.short[
                  project.jobDetails.unitOfMeasure
                ]
              }
            </p>
          )}
        </div>

        <div className="project-quality-wrapper">
          {project.jobDetails &&
            project.jobDetails.qualityLevels &&
            project.jobDetails.qualityLevels.map((quality) => {
              return (
                <ProjectQualityCard
                  key={quality.name}
                  quality={quality}
                  setProject={setProject}
                  project={project}
                  errors={errors}
                />
              );
            })}
        </div>
      </div>

      <div className="project-input-wrapper">
        <label htmlFor="projectNameOverride">
          Project Name Override{" "}
          <span onClick={() => handleDefault("project_name_override")}>
            Use Default
          </span>
        </label>
        <div className="project-input">
          <input
            type="text"
            id="projectNameOverride"
            className={`${errors.project_name_override ? "invalid" : ""}`}
            placeholder="Project name override"
            value={
              project.project_name_override !== null
                ? project.project_name_override
                : project.cache_project_name
            }
            onChange={(e) =>
              handleProject("project_name_override", e.target.value)
            }
          />
        </div>
      </div>

      <div className="project-input-wrapper">
        <label
          htmlFor="projectDescription"
          className="project-description-label"
        >
          <div>
            Project Description{" "}
            <span onClick={() => handleDefault("job_details_override")}>
              Use Default
            </span>
          </div>
          <span className="planning-checkbox">
            <input
              id="planning"
              type="checkbox"
              onChange={handleForPlanning}
              checked={
                project.job_details_override &&
                project.job_details_override.includes(planningText)
              }
            />
            <label htmlFor="planning">For Planning?</label>
          </span>
        </label>
        <div className="project-input">
          <textarea
            id="projectDescription"
            className={`${errors.job_details_override ? "invalid" : ""}`}
            placeholder="Project description"
            rows="3"
            value={
              project.job_details_override !== null
                ? project.job_details_override
                : project.cache_job_details
            }
            onChange={(e) =>
              handleProject("job_details_override", e.target.value)
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="project-input-wrapper">
            <label htmlFor="projectTrade">Trade</label>
            <div className="project-input">
              <TradesTypeahead
                project={project}
                setProject={setProject}
                errors={errors}
              />
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="project-input-wrapper">
            <label htmlFor="projectPriority">Priority</label>
            <div className="project-input">
              <select
                id="projectPriority"
                value={project.project_priority_ref_id}
                onChange={(e) =>
                  handleProject("project_priority_ref_id", e.target.value)
                }
              >
                <option value={1}>Urgent</option>
                <option value={2}>Pressing</option>
                <option value={3}>Cosmetic</option>
              </select>
            </div>
          </div>
        </div>

        {/* <div className="col-4">
          <div className="project-input-wrapper">
            <label htmlFor="projectFeature">Feature</label>
            <div className="project-input">
              <select
                id="projectFeature"
                className={`mb-0 ${errors.property_fact_id ? "invalid" : ""}`}
                value={project.property_fact_id || ""}
                onChange={(e) =>
                  setProject({
                    ...project,
                    property_fact_id: parseInt(e.target.value),
                  })
                }
              >
                <option value="" disabled>
                  Select Feature
                </option>
                {property.rooms.map((room) => {
                  return (
                    <optgroup
                      key={room.area_name + " #" + room.area_instance}
                      label={room.area_name + " #" + room.area_instance}
                    >
                      {room.features.map((feature) => {
                        return (
                          <option
                            key={feature.property_fact_id}
                            value={feature.property_fact_id}
                          >
                            {feature.feature_name} ({room.area_name})
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>
            </div>
            <a href="#" onClick={() => setShowCreateFeatureModal(true)}>
              + Create Feature
            </a>
          </div>
        </div> */}
      </div>

      {userCanEdit && (
        <div className="project-footer">
          <button className="btn-primary" onClick={updateProject}>
            Update Project
          </button>
          <button className="btn-secondary" onClick={getNextProject}>
            Skip Project
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              setConfirmModalOptions({
                title: "Move & Delete Incomplete Project",
              });
              setShowDeleteModal(true);
            }}
          >
            Not Enough Information
          </button>
          <button
            className="btn-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Project
          </button>
        </div>
      )}
    </div>
  );
}

export default withRouter(EditProject);
