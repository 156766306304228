import "./index.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TradesTypeaheadField from "../FormFields/trades.typeahead";
import axios from "../../services/axios";
import { formatCost } from "../../services/helpers";

const mapStateToProps = (state) => ({
  sortedRooms: state.properties.sortedRooms,
});

function ReviewProjectCard({
  getProjects,
  key,
  project,
  setBookmark,
  setProjectId,
  sort,
  updatePageNumber,
  updateStep,
  userCanEdit,
}) {
  const [propertyFactId, setPropertyFactId] = useState(
    project.property_fact_id
  );
  const [featureProblem, setFeatureProblem] = useState(project.feature_problem);
  const [pageStart, setPageStart] = useState(project.inspection_page_num_start);
  const [pageEnd, setPageEnd] = useState(project.inspection_page_num_end);
  const [problemComment, setProblemComment] = useState(
    project.inspection_problem_comment
  );
  const [projectName, setProjectName] = useState(
    project.project_name_override || project.cache_project_name
  );
  const [jobDetails, setJobDetails] = useState(
    project.job_details_override || project.cache_job_details
  );
  const [projectPriority, setProjectPriority] = useState(
    project.project_priority_ref_id
  );
  const [showCosts, setShowCosts] = useState(false);
  const [tradeId, setTradeId] = useState(project.trade_id);
  const [isUpdated, setIsUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const { sortedRooms } = useSelector(mapStateToProps);

  useEffect(() => {
    setErrors({
      property_fact_id: !project.property_fact_id,
      feature_problem: !project.feature_problem,
      inspection_page_num_start: !project.inspection_page_num_start,
      inspection_page_num_end: !project.inspection_page_num_end,
      inspection_problem_comment: !project.inspection_problem_comment,
      project_name_override: !(
        project.project_name_override || project.cache_project_name
      ),
      job_details_override: !(
        project.job_details_override || project.cache_job_details
      ),
      project_priority_ref_id: !project.project_priority_ref_id,
      trade_id: !project.trade_id,
    });
  }, []);

  useEffect(() => {
    if (tradeId && tradeId !== project.trade_id) {
      updateProject();
    }
  }, [tradeId]);

  useEffect(() => {
    if (problemComment && !featureProblem) {
      let title = problemComment.split(".");
      setFeatureProblem(title[0]);
    }
  }, [problemComment]);

  async function updateProject(e, projectData) {
    e.preventDefault();

    let data = projectData || {
      property_fact_id: propertyFactId,
      feature_problem: featureProblem,
      inspection_page_num_start: pageStart,
      inspection_page_num_end: pageEnd,
      inspection_problem_comment: problemComment,
      project_name_override: projectName,
      job_details_override: jobDetails,
      project_priority_ref_id: projectPriority || 1,
      trade_id: tradeId,
    };

    try {
      const updatedProjectResponse = await axios.put(
        `/domoreport/uploader/project/${project.domoreport_project_log_id}`,
        data
      );
      if (updatedProjectResponse.data) {
        getProjects(sort, true);
        setIsUpdated(true);

        setErrors({
          property_fact_id: !propertyFactId,
          feature_problem: !featureProblem,
          inspection_page_num_start: !pageStart,
          inspection_page_num_end: !pageEnd,
          inspection_problem_comment: !problemComment,
          project_name_override: !projectName,
          job_details_override: !jobDetails,
          project_priority_ref_id: !projectPriority,
          trade_id: !tradeId,
        });
      }
    } catch (error) {
      setIsUpdated(false);
    }
  }

  function getPriority(id) {
    let priority = "Urgent";
    switch (id) {
      case 1:
        priority = "Urgent";
        break;
      case 2:
        priority = "Pressing";
        break;
      case 3:
        priority = "Cosmetic";
        break;
      default:
        priority = "Urgent";
    }
    return priority;
  }

  function handleProjectClick(e) {
    e.preventDefault();
    setBookmark(`project${project.domoreport_project_log_id}`);
    setProjectId(project.domoreport_project_log_id);
    updateStep(2);
  }

  async function handleDefault(e, key) {
    e.preventDefault();
    if (key === "featureProblem") {
      if (problemComment) {
        let title = problemComment.split(".");
        setFeatureProblem(title[0]);
        updateProject(e, { feature_problem: title[0] });
      }
    } else if (key === "projectName") {
      setProjectName(project.cache_project_name);
      updateProject(e, { project_name_override: project.cache_project_name });
    } else if (key === "jobDetails") {
      setJobDetails(project.cache_job_details);
      updateProject(e, { job_details_override: project.cache_job_details });
    }
  }

  return (
    <div
      className={`review-project-card border-${getPriority(
        project.project_priority_ref_id
      )}`}
      id={`project${project.domoreport_project_log_id}`}
      key={key}
      onClick={() => setIsUpdated(false)}
    >
      <div className="review-card-header">
        <p>
          <a href="#" onClick={handleProjectClick}>
            {project.project_name_override || project.cache_project_name}
          </a>
        </p>
        {isUpdated ? (
          <div className="success-badge" onClick={() => setIsUpdated(false)}>
            <i className="fa fa-check" />
          </div>
        ) : (
          <div />
        )}

        <p
          className={`md-${getPriority(
            project.project_priority_ref_id
          )}-text total-cost`}
          style={{ width: "100%" }}
          onClick={() => setShowCosts(!showCosts)}
        >
          ${project.cache_project_cost_low} - ${project.cache_project_cost_high}{" "}
          <span>
            {showCosts ? (
              <i className="fa fa-caret-up" />
            ) : (
              <i className="fa fa-caret-down" />
            )}
          </span>
        </p>

        <div className={`cost-breakdown row ${showCosts ? "" : "d-none"}`}>
          <div className="col-4">
            <p>
              <i className="fa fa-cogs" /> Materials
            </p>
            <p className="costs">
              {formatCost(
                project.cache_material_cost_low,
                project.cache_material_cost_high
              )}
            </p>
          </div>

          <div className="col-4">
            <p>
              <i className="fa fa-handshake-o" /> Labor Costs
            </p>
            <p className="costs">
              {formatCost(
                project.cache_labor_cost_low,
                project.cache_labor_cost_high
              )}
            </p>
          </div>

          <div className="col-4">
            <p>
              <i className="fa fa-wrench" /> Equipment
            </p>
            <p className="costs">
              {formatCost(
                project.cache_equipment_cost_low,
                project.cache_equipment_cost_high
              )}
            </p>
          </div>

          <div className="col-4">
            <p>
              <i className="fa fa-usd" /> Market Price
            </p>
            <p className="costs">
              {formatCost(project.low_market_price, project.high_market_price)}
            </p>
          </div>

          <div className="col-4">
            <p>
              <i className="fa fa-truck" /> Trip Charge
            </p>
            <p className="costs">
              {formatCost(
                project.cache_licensed_contractor_cost_low,
                project.cache_licensed_contractor_cost_high
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="review-card-body row">
        <div className="col-6">
          <label>Defect Description</label>
          <div className="md-diy-input-wrapper">
            <textarea
              type="text"
              className={`${
                errors.inspection_problem_comment ? "invalid" : ""
              }`}
              value={problemComment}
              onChange={(e) => setProblemComment(e.target.value)}
              onBlur={updateProject}
            />
          </div>
        </div>

        <div className="col-6">
          <label>PDF Pages</label>
          <div className="d-flex">
            <div className="md-diy-input-wrapper page-number mr-1">
              <input
                type="number"
                className={`m-0 ${
                  errors.inspection_page_num_start ? "invalid" : ""
                }`}
                value={pageStart}
                onChange={(e) => setPageStart(e.target.value)}
                onBlur={updateProject}
              />
              <a
                href="#"
                className="m-0"
                onClick={(e) => {
                  e.preventDefault();
                  updatePageNumber(
                    pageStart || project.inspection_page_num_start
                  );
                }}
              >
                <i className="fa fa-search" />
              </a>
            </div>

            <div className="md-diy-input-wrapper page-number mr-1">
              <input
                type="number"
                className={`m-0 ${
                  errors.inspection_page_num_end ? "invalid" : ""
                }`}
                value={pageEnd}
                onChange={(e) => setPageEnd(e.target.value)}
                onBlur={updateProject}
              />
              <a
                href="#"
                className="m-0"
                onClick={(e) => {
                  e.preventDefault();
                  updatePageNumber(pageEnd || project.inspection_page_num_end);
                }}
              >
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
        </div>

        <div className="col-12">
          <label>
            Defect Title{" "}
            <a href="#" onClick={(e) => handleDefault(e, "featureProblem")}>
              Use Default
            </a>
          </label>
          <div className="md-diy-input-wrapper">
            <textarea
              rows="2"
              className={`${errors.feature_problem ? "invalid" : ""}`}
              value={featureProblem}
              onChange={(e) => setFeatureProblem(e.target.value)}
              onBlur={updateProject}
            />
          </div>
        </div>

        <div className="col-12">
          <label>
            Project Name Override{" "}
            <a href="#" onClick={(e) => handleDefault(e, "projectName")}>
              Use Default
            </a>
          </label>
          <div className="md-diy-input-wrapper">
            <input
              type="text"
              className={`${errors.project_name_override ? "invalid" : ""}`}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              onBlur={updateProject}
            />
          </div>
        </div>

        <div className="col-12">
          <label>
            Project Description{" "}
            <a href="#" onClick={(e) => handleDefault(e, "jobDetails")}>
              Use Default
            </a>
          </label>
          <div className="md-diy-input-wrapper">
            <textarea
              rows="2"
              className={`${errors.job_details_override ? "invalid" : ""}`}
              value={jobDetails}
              onChange={(e) => setJobDetails(e.target.value)}
              onBlur={updateProject}
            />
          </div>
        </div>

        <div className="col-12 d-flex">
          <div className="md-diy-input-wrapper mx-1">
            <TradesTypeaheadField
              value={tradeId}
              src="reviewCard"
              updateProject={setTradeId}
              disabled={!userCanEdit}
            />
          </div>

          <div className="md-diy-input-wrapper mx-1">
            <select
              id="priority"
              className={`${errors.project_priority_ref_id ? "invalid" : ""}`}
              value={projectPriority}
              onChange={(e) => setProjectPriority(e.target.value)}
              onBlur={updateProject}
              disabled={!userCanEdit}
            >
              <option value={1}>Urgent</option>
              <option value={2}>Pressing</option>
              <option value={3}>Cosmetic</option>
            </select>
          </div>

          <div className="md-diy-input-wrapper mx-1">
            <select
              id="features"
              className={`${errors.property_fact_id ? "invalid" : ""}`}
              value={propertyFactId}
              onChange={(e) => setPropertyFactId(parseInt(e.target.value))}
              onBlur={updateProject}
              disabled={!userCanEdit}
            >
              {sortedRooms.map((section) => {
                return section.rooms.map((room) => {
                  return (
                    <optgroup
                      label={room.area_name + " #" + room.area_instance}
                      key={room.area_name + " #" + room.area_instance}
                    >
                      {room.features.map((feature) => {
                        return (
                          <option
                            key={feature.property_fact_id}
                            value={feature.property_fact_id}
                          >
                            {feature.feature_name}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                });
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReviewProjectCard;
